import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { Container } from 'components/Container'
import { dashboards, user } from 'modules'

import styles from './styles.module.css'

export const MainPage: FC = () => {
  const dispatch = useDispatch()

  const chartItems = useSelector(dashboards.useGetMain)
  const count = useSelector(user.useGetCountData)

  useEffect(() => {
    dispatch(user.actions.getCount())
    dispatch(dashboards.actions.getMainDashboards())
  }, [dispatch])

  const { pathname, hash, key } = useLocation()

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0)
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
        }
      }, 0)
    }
  }, [pathname, hash, key])

  return (
    <>
      <section
        className={`${styles.section} ${styles.section__main}`}
        id='main'
      >
        <Container>
          <div className={styles.block}>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <h1 className={styles.block__headline}>
                  Автоматизированная информационная система «Госактивы»
                </h1>
              </Grid>
              <Grid item>
                <p className={styles.block__subheadline}>
                  Единый реестр государственных активов - юридических лиц с
                  долей государственной собственности
                </p>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
      <Container>
        <section className={styles.section}>
          <h2 className={styles.section__headline}>
            Статистика АИС «Госактивы»
          </h2>
          <Grid container spacing={6} direction='column'>
            <Grid item>
              <div className={styles.wrContentStat}>
                <div className={styles.blockContentStat}>
                  <Grid container spacing={6} direction='column'>
                    <Grid item sm={12}>
                      <p className={styles['info-text']}>
                        Система предназначена для создания единого реестра
                        государственных активов – юридических лиц с долей
                        государственной собственности.
                      </p>
                    </Grid>
                    <Grid item sm={12}>
                      <Grid container spacing={2}>
                        <Grid item sm={4}>
                          <div className={styles.amount__wrapper}>
                            <p className={styles.amount}>
                              {chartItems
                                ? chartItems.organizationsAmount
                                : 'Нет данных'}
                            </p>
                            <span className={styles.amount__subline}>
                              Количество организаций с долей государства
                            </span>
                          </div>
                        </Grid>
                        <Grid item sm={4}>
                          <div className={styles.amount__wrapper}>
                            <p className={styles.amount}>
                              {chartItems
                                ? chartItems.stocksAmount
                                : 'Нет данных'}
                            </p>
                            <span className={styles.amount__subline}>
                              Общее количество акций
                            </span>
                          </div>
                        </Grid>
                        <Grid item sm={4}>
                          <div className={styles.amount__wrapper}>
                            <p className={styles.amount}>
                              {count ? count.countUser : 'Нет данных'}
                            </p>
                            <span className={styles.amount__subline}>
                              Количество пользователей в АИС
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.blockContentStatImg}>
                  <img src='/assets/svg/home_logo.svg' alt='logotype' />
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className={styles.wrContentDiagram}>
                <div className={styles.blockContent}>
                  <div className={styles.chart__container}>
                    <h3 className={styles.chart__headline}>
                      График изменения количества организаций с долей
                      государства
                    </h3>
                    {chartItems ? (
                      <ResponsiveContainer
                        className={styles['chart__responsive-container']}
                      >
                        <AreaChart
                          className={styles.chart__area}
                          data={chartItems.organizations}
                        >
                          <XAxis
                            axisLine={false}
                            tickLine={false}
                            dataKey='name'
                          />
                          <YAxis axisLine={false} tickLine={false} />
                          <Tooltip />
                          <Area
                            type='monotone'
                            dataKey='количество'
                            stroke='rgba(0, 30, 120, 1)'
                            fill='rgba(0, 30, 120, 1)'
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    ): <span className={styles.diagram_text}>Нет данных</span>}
                  </div>
                </div>
                <div className={styles.blockContent}>
                  <div className={styles.chart__container}>
                    <h3 className={styles.chart__headline}>
                      График изменения количества акций
                    </h3>
                    {chartItems ? (
                      <ResponsiveContainer
                        className={styles['chart__responsive-container']}
                      >
                        <AreaChart
                          className={styles.chart__area}
                          data={chartItems.stocks}
                        >
                          <XAxis
                            axisLine={false}
                            tickLine={false}
                            dataKey='name'
                          />
                          <YAxis axisLine={false} tickLine={false} />
                          <Tooltip />
                          <Area
                            type='monotone'
                            dataKey='количество'
                            stroke='rgba(0, 95, 120, 1)'
                            fill='rgba(0, 95, 120, 1)'
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    ) : <span className={styles.diagram_text}>Нет данных</span>}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </section>
        <section className={styles.section} id='general'>
          <h2 className={styles.section__headline}>Общие сведения о системе</h2>
          <Grid container direction='column' spacing={4} columnSpacing={2}>
            <Grid item>
              <div className={styles.rowWr}>
                <div className={styles.wrContentDiagram}>
                  <div className={styles.blockContentHalf}>
                    <div className={styles.infoSvg}>
                      <img
                        className={styles.intelligence__icon}
                        src='/assets/svg/icon_1.svg'
                        alt='icon_1'
                      />
                    </div>
                    <span className={styles.intelligence__text}>
                      Создание и поддержание в актуальном состоянии единого
                      реестра государственных активов
                    </span>
                  </div>
                  <div className={styles.blockContentHalf}>
                    <div className={styles.infoSvg}>
                      <img
                        className={styles.intelligence__icon}
                        src='/assets/svg/icon_2.svg'
                        alt='icon_2'
                      />
                    </div>
                    <span className={styles.intelligence__text}>
                      Консолидированный просмотр информации о государственных
                      активах, включая возможность перехода по иерархии активов
                    </span>
                  </div>
                </div>
                <div className={styles.wrContentDiagram}>
                  <div className={styles.blockContentHalf}>
                    <div className={styles.infoSvg}>
                      <img
                        className={styles.intelligence__icon}
                        src='/assets/svg/icon_3.svg'
                        alt='icon_3'
                      />
                    </div>
                    <span className={styles.intelligence__text}>
                      Удобный простой и расширенный поиск данных в реестре и
                      формирование, сохранение и выгрузка отчетов
                    </span>
                  </div>
                  <div className={styles.blockContentHalf}>
                    <div className={styles.infoSvg}>
                      <img
                        className={styles.intelligence__icon}
                        src='/assets/svg/icon_4.svg'
                        alt='icon_4'
                      />
                    </div>
                    <span className={styles.intelligence__text}>
                      Объединение данных из различных информационных ресурсов с
                      информацией о государственных активах
                    </span>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <h3 className={styles.base__headline}>
                    Нормативно-правовая база
                  </h3>
                </Grid>
                <Grid item>
                  <div className={styles.wrContentDiagram}>
                    <div className={styles.blockContentHalf}>
                      <span className={styles.intelligence__text}>
                        Деятельность АИС «Госактивы» регулируется следующими
                        нормативно-правовыми актами Республики Беларусь
                      </span>
                    </div>
                    <div className={styles.blockContentHalf}>
                      <Grid container direction='column'>
                        <Grid item>
                          <Link
                            className={styles.intelligence__link}
                            to='/assets/Распоряжение.docx'
                            target='_blank'
                            download
                          >
                            Распоряжение Премьер-министра Республики Беларусь от
                            21.10.2022 г. N350р
                          </Link>
                        </Grid>
                        <Grid item>
                          <span>
                            «Об автоматизированной информационной системе
                            «Госактивы»
                          </span>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
        <section className={styles.section} id='instructions'>
          <h2 className={styles.section__headline}>Инструкции</h2>
          <Accordion>
            <AccordionSummary
              className={styles.accordion__summary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography className={styles.accordion__typography_head}>
                Подключение к АИС «Госактивы»
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordion__details}>
              <Typography className={styles.accordion__typography_body}>
                <p className={styles['accordion__typography-paragraph']}>
                  В рамках оказания услуги 6.0.00 дополнительный атрибутный
                  сертификат для ФСЗН не издается.{' '}
                  <Link
                    className={styles.inctructions__link}
                    to='https://nces.by/service/faq/#2'
                    target='_blank'
                  >
                    Получить АС ФСЗН
                  </Link>{' '}
                  можно после получения основного сертификата в рамках услуги
                  6.1-2.43.
                </p>
                <p className={styles['accordion__typography-paragraph']}>
                  Для получения услуги необходимо:
                </p>
                <p className={styles['accordion__typography-paragraph']}>
                  <ol>
                    <li>
                      1. авторизоваться на{' '}
                      <Link
                        className={styles.inctructions__link}
                        to='https://portal.gov.by/PortalGovBy/faces/wcnav_defaultSelection?_afrLoop=491498162189762&_afrWindowMode=0&_afrWindowId=null'
                        target='_blank'
                      >
                        Едином портале электронных услуг
                      </Link>{' '}
                      с использованием действующего сертификата РУЦ ГосСУОК
                      (через браузер Internet Explorer);
                    </li>
                    <li>
                      2. в личном кабинете выбрать услугу 6.0.00 «Дистанционное
                      издание сертификата индивидуального предпринимателя
                      (физического лица) с использованием действующего
                      сертификата»;
                    </li>
                    <li>
                      3. ознакомиться и подтвердить свое согласие с{' '}
                      <Link
                        className={styles.inctructions__link}
                        to='https://portal.gov.by/PortalGovBy/faces/oracle/webcenter/portalapp/pages/registration/rules.jspx'
                        target='_blank'
                      >
                        Пользовательским соглашением{' '}
                      </Link>
                      и{' '}
                      <Link
                        className={styles.inctructions__link}
                        to='https://nces.by/wp-content/uploads/11Public_RUC_distance.pdf'
                        target='_blank'
                      >
                        Публичным договором
                      </Link>
                      ;
                    </li>
                    <li>
                      4. создать файл запроса в соответствии с инструкцией(
                      <Link
                        className={styles.inctructions__link}
                        to='https://nces.by/wp-content/uploads/instrukciya-po-sozdaniyu-zaprosa-na-distancionno-izdaniya-sertifikata-ip.pdf'
                        target='_blank'
                      >
                        для индивидуального предпринимателя
                      </Link>
                      {', '}                     
                      <Link
                        className={styles.inctructions__link}
                        to='https://nces.by/wp-content/uploads/instrukciya-po-sozdaniyu-zaprosa-na-distancionnoe-izdanie-sertifikata-fl.pdf'
                        target='_blank'
                      >
                        для физического лица
                      </Link>
                      ). Данные для запроса будут получены автоматически из
                      действующего сертификата. Останется только указать срок
                      действия нового сертификата (для ИП — на 1 или 2 года, для
                      физлиц — еще и на 3 года); В случае если данные клиента
                      для файла запроса на новый сертификат изменились по
                      отношению к действующему сертификату, за получением нового
                      сертификата придется{' '}
                      <Link
                        className={styles.inctructions__link}
                        to='https://nces.by/pki/contacts/'
                        target='_blank'
                      >
                        обратиться в регистрационный центр
                      </Link>{' '}
                      лично.
                    </li>
                    <li>
                      5. импортировать созданный файл запроса и указать свои
                      контактные данные;
                    </li>
                    <li>6. выбрать способ оплаты и оплатить услугу. </li>
                  </ol>
                </p>
                <p className={styles['accordion__typography-paragraph']}>
                  Произвести оплату можно только в процессе получения услуги
                  одним из способов:через ASSIST, введя реквизиты банковской
                  карты;через ЕРИП, используя следующий путь в дереве каталогов
                  услуг: ЕРИП – Электронное правительство – ОАИС – Электронные
                  услуги ОАИС. Оплата с использованием платежного поручения не
                  допускается.
                </p>
                <p className={styles['accordion__typography-paragraph']}>
                  После подтверждения факта совершения оплаты система в
                  автоматическом режиме издаст сертификат и сохранит его в{' '}
                  <Link
                    className={styles.inctructions__link}
                    to='https://store.nces.by/'
                    target='_blank'
                  >
                    xранилище сертификатов
                  </Link>
                  . Реквизиты доступа к облачному хранилищу отобразятся в личном
                  кабинете пользователя.
                </p>
                <p className={styles['accordion__typography-paragraph']}>
                  На адрес электронной почты и в sms-сообщении клиент получит
                  инструкции о том, как воспользоваться новым сертификатом.
                </p>
                <button className={styles.accordion__button}>
                  Скачать шаблон заявки
                </button>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              className={styles.accordion__summary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography className={styles.accordion__typography_head}>
                Внесение новой записи в эталонный перечень
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordion__details}>
              <Typography className={styles.accordion__typography_body}>
                <p className={styles['accordion__typography-paragraph']}>
                  Редактирование и добавление записи в эталонный перечень
                  осуществляется в кабинете Администратора.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              className={styles.accordion__summary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography className={styles.accordion__typography_head}>
                Внесение эталонного перечня Белстат из csv
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordion__details}>
              <Typography className={styles.accordion__typography_body}>
                <p className={styles['accordion__typography-paragraph']}>
                  В файл CSV преобразовываются данные, полученные из Белстата,
                  аналогичные представленному Эталонный перечень. Для этого
                  необходимо:
                </p>
                <p className={styles['accordion__typography-paragraph']}>
                  <ol>
                    <li>
                      1. открыть документ;
                    </li>
                    <li>
                      2. в таблице «Органы государственной власти и управления»
                      эталонного перечня удалить переносы строк в поле «Полное
                      наименование»;
                    </li>
                    <li>
                      3. документ эксель сохранить с типом файла «CSV
                      (разделители запятые)».
                    </li>
                  </ol>
                </p>
                <p className={styles['accordion__typography-paragraph']}>
                  Автоматическая загрузка вызывается администратором системы из
                  функционала Управление эталонным перечнем.
                </p>
                <p className={'accordion__typography-paragraph'}>
                  Файл CSV загружается полностью, начиная со второй строки,
                  первая строка – название полей.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </section>
        <section className={styles.section} id='contacts'>
          <h2 className={styles.section__headline}>Остались вопросы?</h2>
          <div className={styles.wrContent}>
            <div className={styles.blockContent}>
              <p className={styles.contact}>
                Oтправьте свой вопрос на e-mail или обратитесь в службу
                поддержки пользователей
              </p>
            </div>
            <div className={styles.blockContent}>
              <Grid container direction='column' spacing={1}>
                <Grid item sm={12}>
                  <p className={styles.contact}>
                    (+375) 17 287-81-87, (+375) 29 663-84-05
                  </p>
                </Grid>
                <Grid item sm={12}>
                  <p className={styles.contact}>e-mail: dio@agsr.by</p>
                </Grid>
              </Grid>
            </div>
          </div>
        </section>
      </Container>
    </>
  )
}

import { Grid } from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Container } from 'components/Container'

import styles from './styles.module.css'

export const Footer: FC = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.wr}>
          <div className={styles.block}>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <img
                  className={styles.logo}
                  src='/assets/Logo.svg'
                  alt='logotype'
                />
              </Grid>
              <Grid item>
                <Link
                  className={styles.footer__link}
                  to='https://www.agsr.by/upload/iblock/e6e/p9gih0pcz8vr08dzo9xtuk2o3uwpf1bn/Politika_v_otnoshenii_obrabotki_personalnykh_dannykh_sayt_11.03.24_.pdf'
                  target='_blank'
                >
                  Политика обработки персональных данных
                </Link>
              </Grid>
            </Grid>
          </div>
          <div className={styles.block}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <p className={styles['footer__contact-header']}>Контакты</p>
              </Grid>
              <Grid item>
                <p className={styles.footer__contact}>
                  (+375) 17 287-81-87, (+375) 29 663-84-05
                </p>
              </Grid>
              <Grid item>
                <p className={styles.footer__contact}>e-mail: dio@agsr.by</p>
              </Grid>
              <Grid item>
                <p className={styles.footer__contact}>
                  г. Минск, ул. К.Цеткин, д. 24-705
                </p>
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid container spacing={4}>
          <Grid item sm={6}></Grid>
        </Grid>
      </Container>
    </footer>
  )
}
